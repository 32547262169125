/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import Header from './Header'
import './Repairs.scss'
import SideBar from './SideBar'
import { Link, useParams } from 'react-router-dom';
import { Table, Button, Form, Card, Image, Modal } from 'react-bootstrap';
import { serverUrl } from "../../serverUrl";
import { format } from 'date-fns';
import jwt_decode from "jwt-decode";
// import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from "@react-pdf/renderer";

// images
import Lottie from 'react-lottie';
import SadEmoji from '../../assets/lottie/sad_emoji.json'

import { useTheme } from './ThemeContext';

function Repairs() {
    const { theme } = useTheme();

    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }


    // declaration of variables
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [landlordApartment, setLandlordApartment] = useState([])
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});
    const [houseCount, setHouseCount] = useState({});
    const [selectedApartmentRepairsTotal, setSelectedApartmentRepairsTotal] = useState({});
    const [filterType, setFilterType] = useState('current_month');
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showModal, setShowModal] = useState(false);

    const apartmentRepairTotal = {};


    // get the user logged in
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const apartmentImage = {};
    const [showModalInactive, setShowModalInactive] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState([]);
    const [subscription, setSubscription] = useState([]);
    const [subscriptionAmount, setSubscriptionAmount] = useState("");
    // get the apartments and the details needed within the apartment
    const handleCloseModalInactive = () => {

        setShowModalInactive(false);
    }
    const fetchRepairs = async () => {
        try {
          // Fetch landlord data
          const landlordRes = await serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config);
          const landlordIds = landlordRes.data.results.map((landlord) => landlord.id);
    
          // Fetch apartments for each landlord
          const apartmentPromises = landlordIds.map((landlordId) =>
            serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
          );
          const apartmentResponses = await Promise.all(apartmentPromises);
          const apartments = apartmentResponses.flatMap((res) => res.data.results);
    
          // Fetch repairs for each apartment
          const repairPromises = apartments.map(async (apartment) => {
            let url = `/landlord/react-repairs-total/?apartment_id=${apartment.id}`;
    
            if (filterType === "current_month") {
              url += `&year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`;
            } else if (filterType === "yearly") {
              url += `&year=${year}`;
            } else if (filterType === "monthly") {
              url += `&year=${year}&month=${month}`;
            } else if (filterType === "date_range" && startDate && endDate) {
              url += `&start_date=${startDate}&end_date=${endDate}`;
            }
    
            try {
              const res = await serverUrl.get(url, config);
              return { apartmentId: apartment.id, total: res.data.total_repairs_amount };
            } catch (error) {
              console.error(error);
              return { apartmentId: apartment.id, total: 0 }; // Default to 0 if there's an error
            }
          });
    
          // Wait for all repair data to be fetched
          const repairResults = await Promise.all(repairPromises);
    
          // Convert the results into an object
          const repairTotal = repairResults.reduce((acc, { apartmentId, total }) => {
            acc[apartmentId] = total;
            return acc;
          }, {});
    
          // Update the state with the final data
          setSelectedApartmentRepairsTotal(repairTotal);
        } catch (error) {
          console.error("Error fetching repairs:", error);
        }
      };
    // the total repair filter 
    useEffect(() => {
        fetchRepairs();
    }, [filterType, year, month, startDate, endDate]);



    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setFilterType(selectedFilter);

        if (selectedFilter !== 'current_month') {
            setShowModal(true);
        } else {
            fetchRepairs(); // Fetch immediately for "Current Month"
        }
    };

    const handleApplyFilter = () => {
        setShowModal(false); // Close the modal
        fetchRepairs(); // Fetch data with selected filters
    };

    useEffect(() => {
        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                Promise.all(landlordApartmentPromises)
                    .then((results) => {
                        // i am merging the sub-arrays into a new array
                        const apartments = results.flat();
                        setLandlordApartment(apartments);
                        // getting the houses in the apartment
                        const houseCountPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: 0 };
                                });
                        });

                        // set count of the number of houses that are in that apartment
                        Promise.all(houseCountPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const houseCountObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseCountObj[apartmentId] = count;
                                });
                                setHouseCount(houseCountObj);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        // get the images of the apartments based on the apartment id 
                        apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/apartment-images/?apartment_id=${apartment.id}`, config)
                                .then((res) => {
                                    res.data.results.forEach((res) => {
                                        apartmentImage[res.apartment] = res.image;
                                    })

                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                        setSelectedApartmentImage(apartmentImage)

                        //get the total repairs of the houses in each apartment
                        // apartments.map((apartment) => {
                        //     return serverUrl.get(`/landlord/react-repairs-total/?apartment_id=${apartment.id}`, config)
                        //         .then((res) => {
                        //             apartmentRepairTotal[apartment.id] = res.data.total_repairs_amount;
                        //         })
                        //         .catch((error) => {
                        //             console.log(error);
                        //         });
                        // });
                        // setSelectedApartmentRepairsTotal(apartmentRepairTotal)

                        //get the details based on the wallet data
                        // the subscription amount
                        const unitAmountSubscriptionPromises = apartments.map(
                            (apartment) => {
                                return serverUrl
                                    .get(
                                        `/landlord/subscription-amount-units/?apartment_id=${apartment.id}`,
                                        config
                                    )
                                    .then((res) => ({
                                        apartmentId: apartment.id,
                                        data: res.data.results[0],
                                        units: res.data.units,
                                    }))
                                    .catch((error) => {
                                        console.log(error);
                                        return { apartmentId: apartment.id };
                                        setIsLoading(false);
                                    });
                            }
                        );
                        // set count of the number of houses that are occupied in that apartment
                        Promise.all(unitAmountSubscriptionPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const houseOccupiedObj = {};
                                results.forEach(({ apartmentId, data }) => {
                                    houseOccupiedObj[apartmentId] = data;
                                });

                                setSubscriptionAmount(houseOccupiedObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        // get the subscription PLAN
                        const subscriptionPromises = apartments.map((apartment) => {
                            return serverUrl
                                .get(
                                    `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                                    config
                                )
                                .then((res) => ({
                                    apartmentId: apartment.id,
                                    subscription: res.data.subscription,
                                }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id };
                                });
                        });

                        // get the subscription status
                        const subscriptionStatusPromises = apartments.map((apartment) => {
                            return serverUrl
                                .get(
                                    `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                                    config
                                )
                                .then((res) => ({
                                    apartmentId: apartment.id,
                                    subscription: res.data.results,
                                }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id };
                                });
                        });
                        Promise.all(subscriptionPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const subscriptionObj = {};
                                results.forEach(({ apartmentId, subscription }) => {
                                    subscriptionObj[apartmentId] = subscription;
                                });
                                setSubscriptionStatus(subscriptionObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        // set the response of the subscriptions plan
                        Promise.all(subscriptionStatusPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const subscriptionObj = {};
                                results.forEach(({ apartmentId, subscription }) => {
                                    subscriptionObj[apartmentId] = subscription;
                                });
                                setSubscription(subscriptionObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [landlordApartment]);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 11) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 11) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/repairs/repairs${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SadEmoji,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            <div className='repairs'>
                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>PROPERTIES EXPENSES</p>

                </div>

                {/* inactive subscription modal */}
                <Modal className={`d-none inactiveModal-${theme}`} show={showModalInactive} onHide={handleCloseModalInactive} style={{ marginTop: "7.5rem" }} size='lg'>

                    <Modal.Header closeButton>
                        <Modal.Title>{ }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card className="text-center border-0 position-relative">
                            <div className="card-background"></div>
                            <div className='card-body-content'>
                                <h3 className='text-uppercase'>Your plan is not activated</h3>
                                <p className='w-75 text-center mx-auto'>It seems you have uncleared invoices. In order to continue enjoying our services, please take a moment to settle them.</p>
                                <div className="d-flex justify-content-center">
                                    <Lottie
                                        options={defaultOptions}
                                        height={200}
                                        width={400}
                                    />
                                </div>
                                <p>We value your continued presence and support. We kindly ask you to activate your plan by settling the outstanding invoices.</p>
                                <Button className='w-auto d-flex text-center mb-4 ' style={{ margin: "0 auto" }}>
                                    <Link className='text-white text-decoration-none' to='/subscription'>
                                        ACTIVATE NOW
                                    </Link>
                                </Button>
                            </div>
                        </Card>


                    </Modal.Body>
                </Modal>

                {/* filtred calendar */}
                <div>
                    {/* Filter Dropdown */}
                    <div className='filterCalendar'>
                        <div className='input-group'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text'>
                                    <i className="bi bi-calendar" />
                                </span>
                            </div>
                            <Form.Select
                                size='sm'
                                className='formCal'
                                value={filterType}
                                onChange={handleFilterChange}
                            >
                                <option value="current_month">Current Month</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                                <option value="date_range">Date Range</option>
                            </Form.Select>
                        </div>
                    </div>



                    {/* Modal for Selecting Year/Month/Date Range */}
                    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Select {filterType === 'monthly' ? 'Month & Year' : filterType === 'yearly' ? 'Year' : 'Date Range'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {filterType === 'yearly' && (
                                <Form.Group>
                                    <label>Select Year</label>
                                    <Form.Control
                                        as="select"
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                    >
                                        {[...Array(10)].map((_, i) => {
                                            const y = new Date().getFullYear() - i;
                                            return <option key={y} value={y}>{y}</option>;
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            )}

                            {filterType === 'monthly' && (
                                <>
                                    <Form.Group>
                                        <label>Select Year</label>
                                        <Form.Control
                                            as="select"
                                            value={year}
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            {[...Array(10)].map((_, i) => {
                                                const y = new Date().getFullYear() - i;
                                                return <option key={y} value={y}>{y}</option>;
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mt-2">
                                        <label>Select Month</label>
                                        <Form.Control
                                            as="select"
                                            value={month}
                                            onChange={(e) => setMonth(e.target.value)}
                                        >
                                            {Array.from({ length: 12 }, (_, i) => i + 1).map(m => (
                                                <option key={m} value={m}>
                                                    {format(new Date(2024, m - 1), 'MMMM')}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </>
                            )}

                            {filterType === 'date_range' && (
                                <>
                                    <Form.Group>
                                        <label>Start Date</label>
                                        <Form.Control
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mt-2">
                                        <label>End Date</label>
                                        <Form.Control
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                            <Button variant="primary" onClick={handleApplyFilter}>Apply Filter</Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>
                    {landlordApartment.map((apartmentDetail, index) =>
                        <Card className='house'>
                            <Link
                                style={{ display: "contents" }}
                                className=" text-decoration-none "
                                to={
                                    apartmentDetail.num_houses !== 0
                                        ? subscriptionStatus[apartmentDetail.id] &&
                                            subscriptionStatus[apartmentDetail.id] === "No subscription found"
                                            ? `/new-plan/${apartmentDetail.id}`
                                            : subscription[apartmentDetail.id] &&
                                                subscription[apartmentDetail.id].length !== 0 &&
                                                subscription[apartmentDetail.id][0].status !== "paid"
                                                ? `/wallet-data/${apartmentDetail.id}`
                                                : `/selected-apartment-repairs/${apartmentDetail.id}`
                                        : `/selected-apartment-repairs/${apartmentDetail.id}`
                                }
                            >
                                {/* <Link to={`/selected-apartment-repairs/${apartmentDetail.id}`} className='text-decoration-none'> */}
                                <Card.Body>
                                    <div className="image-container">
                                        {selectedApartmentImage[apartmentDetail.id] && selectedApartmentImage[apartmentDetail.id] ?
                                            <Image src={selectedApartmentImage[apartmentDetail.id]} style={{ borderRadius: "0.5em" }} className="" alt={index}></Image> :
                                            <Image src={backgroundImageApartment[apartmentDetail.id]} style={{ borderRadius: "0.5em" }} className=""></Image>
                                        }
                                        <div className="badge">{houseCount[apartmentDetail.id]} Units</div>
                                        <p className='text-muted float-left'>{apartmentDetail.location}</p><br></br>

                                    </div>
                                    <p className='houseName'>{apartmentDetail.name}</p>
                                    <p className='houseName'>Total Repairs: Ksh.{selectedApartmentRepairsTotal[apartmentDetail.id]?.toLocaleString() || 0}</p>
                                    <div className='buttonsProperties'>
                                        <Link
                                            style={{ display: "contents" }}
                                            className="text-danger text-decoration-none "
                                            to={
                                                apartmentDetail.num_houses !== 0
                                                    ? subscriptionStatus[apartmentDetail.id] &&
                                                        subscriptionStatus[apartmentDetail.id] === "No subscription found"
                                                        ? `/new-plan/${apartmentDetail.id}`
                                                        : subscription[apartmentDetail.id] &&
                                                            subscription[apartmentDetail.id].length !== 0 &&
                                                            subscription[apartmentDetail.id][0].status !== "paid"
                                                            ? `/wallet-data/${apartmentDetail.id}`
                                                            : `/selected-apartment-repairs/${apartmentDetail.id}`
                                                    : `/selected-apartment-repairs/${apartmentDetail.id}`
                                            }
                                        >
                                            {/* <Link to={`/selected-apartment-repairs/${apartmentDetail.id}`} className='text-danger text-decoration-none'> */}

                                            View Repairs <i className='bi bi-arrow-right'></i></Link>


                                    </div>
                                </Card.Body>

                            </Link>

                        </Card>)}



                </div>

            </div>
        </>
    )
}

export default Repairs