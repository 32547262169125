/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Table, Form, FormControl } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Header from "./Header";
import SideBar from "./SideBar";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import "./Dashboard.scss";

export default function UnpaidInvoice() {
    const navigate = useNavigate();
    const [showSidebar, setShowSidebar] = useState(true);
    const [unpaidInvoice, setUnpaidInvoice] = useState([]);
    const [statusFilter, setStatusFilter] = useState("all"); // New state for status filter
    const [apartmentFilter, setApartmentFilter] = useState("all"); // New state for apartment filter
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [sortType, setSortType] = useState("invoice");
    const [sortOrder, setSortOrder] = useState("asc");

    const itemsPerPage = 10;

    // Get access token
    const accessToken = localStorage.getItem("authTokens");
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = { headers: { Authorization: `Bearer ${access}` } };

    useEffect(() => {
        const token = localStorage.getItem("authTokens");
        if (!token) {
            navigate("/login");
            return;
        }

        const parsedTokens = JSON.parse(token);
        const decodedToken = jwt_decode(token);
        const userId = decodedToken.user_id;

        serverUrl
            .get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                const landlordId = res.data.results[0].id;
                serverUrl
                    .get(`/landlord/${landlordId}/unpaid-invoices`, config)
                    .then((res) => {
                        setUnpaidInvoice(res.data.unpaid_invoices);
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
    }, [navigate]);

    const handleSearch = (event) => setSearchQuery(event.target.value);
    const handleSort = (sortField) => {
        setSortType(sortField);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };
    const handleStatusFilterChange = (event) => setStatusFilter(event.target.value);
    const handleApartmentFilterChange = (event) => setApartmentFilter(event.target.value);

    const sortedData = unpaidInvoice.sort((a, b) => {
        const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
        return sortOrder === "asc" ? (sortValueA > sortValueB ? 1 : -1) : (sortValueA < sortValueB ? 1 : -1);
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        const matchesSearch = searchableFields.includes(searchQuery.toLowerCase());

        const matchesStatus = statusFilter === "all" || item.status === statusFilter;
        const matchesApartment = apartmentFilter === "all" || item.apartment === apartmentFilter;

        return matchesSearch && matchesStatus && matchesApartment;
    });

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const handlePrevPage = () => setCurrentPage((prev) => prev - 1);
    const handleNextPage = () => setCurrentPage((prev) => prev + 1);

    const handleDownloadInvoice = (invoiceId) => {
        serverUrl
            .get(`/tenant/generate_pdf_invoice/?invoice_id=${invoiceId}&download=true`, config)
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", "invoice_report.pdf");
                document.body.appendChild(link);
                link.click();
                toast.success("Successfully Downloaded Your Invoice Report");
            })
            .catch((error) => console.log(error));
    };

    const uniqueApartments = [...new Set(unpaidInvoice.map((item) => item.apartment))];

    const getSortIcon = (field) => {
        if (sortType === field) {
            return sortOrder === "asc" ? (
                <i className="bi bi-chevron-up"></i>
            ) : (
                <i className="bi bi-chevron-down"></i>
            );
        }
        return <i className="bi bi-arrow-down-up"></i>; 
    };

    return (
        <div>
            <Header showSidebar={showSidebar} onButtonClick={() => setShowSidebar(!showSidebar)} />
            {showSidebar && <SideBar />}
            <div className="dashboard mt-5 p-5" style={{ marginLeft: "14rem" }}>
                <h5>List of Unpaid Invoices</h5>
                <div className="filters d-flex justify-content-between mb-3">
                    <Form className="searchBar">
                        <FormControl
                            type="text"
                            placeholder="Search"
                            className='bg-white border border-secondary'
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </Form>
                    <div className="d-flex ">
                        <Form.Select value={statusFilter} onChange={handleStatusFilterChange} className="w-auto">
                            <option value="all">All Payments</option>
                            <option value="unpaid">Unpaid</option>
                            <option value="partial">Partially Paid</option>
                        </Form.Select>
                        <Form.Select value={apartmentFilter} onChange={handleApartmentFilterChange} className="w-auto ms-3">
                            <option value="all">All Apartments</option>
                            {uniqueApartments.map((apartment, index) => (
                                <option key={index} value={apartment}>{apartment}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <Table>
                    <thead className="mt-2">
                        <tr style={{ textAlign: "left" }}>
                            <th onClick={() => handleSort("invoice_id")}>Invoice ID {getSortIcon("invoice_id")}</th>
                            <th onClick={() => handleSort("house_name")}> Unit Number {getSortIcon("house_name")}</th>
                            <th onClick={() => handleSort("apartment")}> Apartment {getSortIcon("apartment")}</th>
                            <th onClick={() => handleSort("amount")}>Amount {getSortIcon("amount")}</th>
                            <th onClick={() => handleSort("balance")}>Balance {getSortIcon("balance")}</th>
                            <th onClick={() => handleSort("status")}>Status {getSortIcon("status")}</th>
                            <th onClick={() => handleSort("reference")}>Reference {getSortIcon("reference")}</th>
                            <th onClick={() => handleSort("utility")}>Utility {getSortIcon("utility")}</th>
                            <th onClick={() => handleSort("tenant_name")}>Tenant {getSortIcon("tenant_name")}</th>
                            <th onClick={() => handleSort("tenant_phone")}>Tenant Phone {getSortIcon("tenant_phone")}</th>
                            <th onClick={() => handleSort("month")}>Month {getSortIcon("month")}</th>
                            <th onClick={() => handleSort("year")}>Year {getSortIcon("year")}</th>
                            {/* <th onClick={() => handleSort("updated_at")}>Date Generated {getSortIcon("updated_at")}</th> */}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedItems.map((invoice) => (
                            <tr key={invoice.id} className="" style={{ textAlign: "left" }}>
                                <td>INV{invoice.invoice_id}</td>
                                <td>
                                    <Link to={`/selected-house/${invoice.house_id}`}>
                                        {invoice.house_name}
                                    </Link>
                                </td>
                                <td>{invoice.apartment}</td>
                                <td>KSH. {parseInt(invoice.amount)?.toLocaleString('en-us')}</td>
                                <td>KSH. {parseInt(invoice.balance_amount)?.toLocaleString('en-us')}</td>
                                <td>
                                    <Button
                                        className={
                                            invoice.status === "partial"
                                                ? "btn btn-sm btn-secondary text-white"
                                                : "btn btn-sm btn-danger"
                                        }
                                    >
                                        {invoice.status}
                                    </Button>
                                </td>
                                <td className='text-capitalize'>{invoice.reference === "expense" ? "Utility" : invoice.reference}</td>
                                <td className='text-capitalize'>{invoice.utility}</td>
                                <td className='text-capitalize'>{invoice.tenant_name}</td>
                                <td className='text-capitalize'>{invoice.tenant_phone}</td>
                                <td className='text-capitalize'>{invoice.month}</td>
                                <td>{invoice.year}</td>
                                {/* <td>{new Date(invoice.updated_at).toISOString().split("T")[0]}</td> */}
                                <td>
                                    <Button
                                        className="btn btn-success btn-sm"
                                        onClick={() => handleDownloadInvoice(invoice.id)}
                                    >
                                        <i className="bi bi-download"></i> PDF
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="pagination">
                    <Button onClick={handlePrevPage} disabled={currentPage === 0}>
                        &lt;
                    </Button>
                    <span>
                        {startIndex + 1}-{Math.min(endIndex, filteredData.length)} of {filteredData.length}
                    </span>
                    <Button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                        &gt;
                    </Button>
                </div>
            </div>
        </div>
    );
}
